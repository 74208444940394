import "@fontsource/dm-sans";
import "@fontsource/dm-serif-display";
import { Link, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import * as styles from "./layout.module.scss";
import { graphql } from "gatsby";

const IconLink = () => (
    <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M-4.86374e-05 12L-4.86374e-05 0L7.19995 6L-4.86374e-05 12Z"
            fill="currentColor"
        />
    </svg>
);

const META_KEYWORDS =
    "Dasha Morris, Dasha Yurovskaya, UX Designer, UI Designer, UX/UI Designer, Data Visualisation, Data Visualization, User Interface, User Experience, Visual Design, User Research";

export default function Layout({
    children,
    pageTitle,
    pageMeta,
    pageKeywords,
}) {
    const data = useStaticQuery(graphql`
        query {
            allContentfulProject(
                filter: { secret: { eq: false } }
                sort: { fields: order }
            ) {
                edges {
                    node {
                        title
                        slug
                    }
                }
            }
        }
    `);

    const linkObjects = data.allContentfulProject.edges.map((e) => e.node);
    const projectLinks = linkObjects.map((link) => (
        <li key={link.slug}>
            <Link to={`/project/${link.slug}`}>
                {link.title} <IconLink />
            </Link>
        </li>
    ));

    return (
        <div className={styles.wrapper}>
            <main className={styles.layout}>
                <Helmet
                    htmlAttributes={{
                        lang: "en",
                    }}
                >
                    <title>{pageTitle}</title>
                    <meta http-equiv="content-language" content="en-au" />
                    <meta charset="UTF-8" />
                    <meta
                        name="keywords"
                        content={`${META_KEYWORDS}${pageKeywords ? " , " : ""}${
                            pageKeywords ? pageKeywords : ""
                        }`}
                    />
                    {pageMeta && <meta name="description" content={pageMeta} />}
                    <meta name="author" content="Dasha Morris" />
                </Helmet>
                {children}
            </main>
            <footer>
                <div className={styles.footerContent}>
                    <div className={styles.hello}>
                        <h2>Say Hello</h2>
                        <p>
                            I'm based in Sydney, Australia.
                            <br />
                            If you’re interested in working with me, ping me at{" "}
                            <a href="mailto:hello@dashamorr.is">
                                hello@dashamorr.is
                            </a>
                        </p>
                    </div>
                    <div className={styles.projects}>
                        <h5>My Projects</h5>
                        <ul>{projectLinks}</ul>
                    </div>
                </div>
            </footer>
        </div>
    );
}
